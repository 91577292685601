<!-- 地图配置 -->
<template>
  <div>
    <el-form-item label="开启轮播">
      <avue-switch v-model="main.activeOption.banner"></avue-switch>
    </el-form-item>
    <template v-if="main.activeOption.banner">
      <el-form-item label="轮播时间">
        <avue-input v-model="main.activeOption.bannerTime"></avue-input>
      </el-form-item>
    </template>
    <template v-if="main.activeOption.type === 0">
      <el-form-item label="地图选择">
        <!--<avue-select :dic="main.DIC.MAP"
                     v-model="main.activeOption.mapData"
                     placeholder="请选择地图"></avue-select>-->
        <div class="el-input-group">
          <avue-input :disabled="true" v-model="main.activeOption.mapName"></avue-input>
          <avue-input :disabled="true" v-show="false" v-model="main.activeOption.mapData"></avue-input>
          <div class="el-input-group__append" style="cursor:pointer;" @click="selectMap">
            <span>+</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="显示恢复按钮">
        <avue-switch v-model="main.activeOption.restoreVis"></avue-switch>
      </el-form-item>
      <el-form-item label="按钮颜色">
        <avue-input v-if="main.activeOption.restoreVis" v-model="main.activeOption.restoreColor"></avue-input>
      </el-form-item>
      <el-form-item label="开启滚动缩放">
        <avue-switch v-model="main.activeOption.isRoam"></avue-switch>
      </el-form-item>
      <el-form-item label="开启label">
        <avue-switch v-model="main.activeOption.disLabel"></avue-switch>
      </el-form-item>
      <el-form-item label="地图比例">
        <avue-slider v-model="main.activeOption.zoom" :max="5" :step="0.1"></avue-slider>
      </el-form-item>
      <el-form-item label="地图缩放范围">
        <avue-input v-model="main.activeOption.zoomlimits" placeholder="使用js数组: [0.4,1.5]"></avue-input>
      </el-form-item>
      <el-form-item label="字体大小">
        <avue-input-number v-model="main.activeOption.fontSize"></avue-input-number>
      </el-form-item>
      <el-form-item label="字体高亮颜色">
        <avue-input-color v-model="main.activeOption.empColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="字体颜色">
        <avue-input-color v-model="main.activeOption.color"></avue-input-color>
      </el-form-item>
      <el-form-item label="区域线">
        <avue-input-number v-model="main.activeOption.borderWidth"></avue-input-number>
      </el-form-item>
      <el-form-item label="区域颜色">
        <avue-input-color v-model="main.activeOption.areaColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="区域高亮颜色">
        <avue-input-color v-model="main.activeOption.empAreaColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="边框颜色">
        <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="tip文字颜色">
        <avue-input-color v-model="main.activeOption.tipColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="tip背景颜色">
        <avue-input-color v-model="main.activeOption.tipBackgroundColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="坐标点颜色">
        <avue-input-color v-model="main.activeOption.pointColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="Tip Formatter">
        <avue-input-color v-model="main.activeOption.tipFormatter" placeholder="格式内容显示方法体"></avue-input-color>
      </el-form-item>
      <el-form-item label="动态颜色对应">
        <avue-input v-model="main.activeOption.dyanmicColor" placeholder="点颜色,分割"></avue-input>
      </el-form-item>
      <el-form-item label="坐标点点击事件">
        <avue-input v-model="main.activeOption.pointClickEvent" placeholder="传入params参数"></avue-input>
      </el-form-item>

      <!-- 区域选择 -->
      <el-divider></el-divider>
      <el-form-item label="开启区域选择">
        <avue-switch v-model="main.activeOption.openAreaSelect"></avue-switch>
      </el-form-item>
      <el-form-item label="区域选中颜色">
        <avue-input-color v-model="main.activeOption.empAreaSelectedColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="选中区域标签颜色">
        <avue-input-color v-model="main.activeOption.empAreaSelectedLabelColor"></avue-input-color>
      </el-form-item>
    </template>
    <template v-if="main.activeOption.type === 1">
      <el-form-item label="图片地址">
        <avue-input v-model="main.activeOption.img"></avue-input>
      </el-form-item>
      <el-form-item label="地图比例">
        <avue-slider v-model="main.activeOption.scale" :max="300"></avue-slider>
      </el-form-item>
    </template>
    <el-dialog
      title="地图选择"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      :visible.sync="mapShow">
      <div class="dialogDiv">
        <el-tree
          ref="tree"
          :data="data"
          empty-text="加载中……"
          node-key="value"
          :current-node-key="nodeKey"
          highlight-current
          show-checkbox
          check-strictly
          @node-click="handleNodeClick"
          @check-change="handleCheckChange"></el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" icon="el-icon-check" @click="submit('ruleForm')">确定</el-button>
        <el-button type="primary" icon="el-icon-close" @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMap, getMapTree } from '@/api/avueData/map'
export default {
  inject: ['main'],
  data() {
    return {
      mapShow: false,
      nodeKey: '',
      data: []
    }
  },
  created() {
    this.loadMapTree()
  },
  methods: {
    handleNodeClick() {},
    handleCheckChange(data, checked) {
      if (checked) {
        this.$refs.tree.setCheckedNodes([data])
      }
    },
    submit() {
      const selectNode = this.$refs.tree.getCheckedKeys()[0]
      const selectNodeName = this.$refs.tree.getCheckedNodes()[0]
      this.main.activeOption.mapData = selectNode
      this.main.activeOption.mapName = selectNodeName.label
      this.mapShow = false
    },
    closeDialog() {
      this.mapShow = false
    },
    loadMapTree() {
      getMapTree().then(res => {
        const result = res.data.data
        this.data = [{
            value: 'CHINA',
            label: '全国',
            children: result
          },
          {
            value: 'WORLD_MAP',
            label: '世界地图'
          }
        ]
      })
    },
    selectMap() {
      this.mapShow = true
    }
  }
}
</script>

<style scoped>
.dialogDiv {
  height: 300px;
  overflow: auto;
}
</style>
