<template>
  <el-row class="build views">
    <container :props="props" :option="option" ref="container" :id="id"></container>
  </el-row>
</template>

<script>
import init from '@/mixins/'
import container from './group/container'
export default {
  data() {
    return {
      id: this.$route ? this.$route.params.id : this.props.id
    }
  },
  props: {
    option: Object,
    props: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted() {},
  components: { container },
  mixins: [init]
}
</script>

<style lang="scss">
@import '../../../styles/style.scss';
</style>
