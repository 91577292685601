<template>
  <div class="build visualization">
    <imglist ref="imglist" @change="handleSetimg"></imglist>
    <headers @msg="toDo"></headers>
    <top ref="top"></top>
    <div class="app" :class="{ 'app--none': !menuFlag }">
      <div class="menu" v-show="menuFlag && menuShow" @click.self="handleMouseDown">
        <p class="title">图层</p>
        <layer ref="layer" :nav="nav"></layer>
      </div>
      <!-- 中间区域 -->
      <div ref="section" class="section">
        <div class="refer-line-img" @click="imgClick">
          <img :src="isShowReferLine ? imgOpenData : imgClose" />
        </div>
        <sketch-rule :thick="thick" :scale="scale" :width="width" :height="height" :startX="startX" :startY="startY" :isShowReferLine="isShowReferLine" :palette="palette" :shadow="shadow" :horLineArr="lines.h" :verLineArr="lines.v" />
        <div ref="screensRef" id="screens" :class="dragSlide ? 'dragghanle' : ''" @mousedown.stop="dragMousedown" @mouseup="dragMouseup" @mousemove="dragMousemove" @wheel="handleWheel" @scroll="handleScroll">
          <div ref="containerRef" class="screen-container">
            <div class="canvas" ref="canvas" :style="canvasStyle">
              <container ref="container" :wscale="scale" :id="id"></container>
            </div>
          </div>
        </div>
      </div>
      <div class=" params" v-show="menuFlag && paramsShow">
        <p class="title">操作</p>
        <el-tabs class="tabs" stretch v-model="tabsActive">
          <el-tab-pane name="0">
            <el-tooltip slot="label" effect="dark" content="配置" placement="top">
              <div><i class="el-icon-setting"></i></div>
            </el-tooltip>
            <el-form label-width="120px" label-position="left" size="mini">
              <!-- 组件配置 -->
              <template v-if="!vaildProp('', [undefined])">
                <p class="title">{{ activeObj.title }}</p>
                <el-form-item label="图层名称">
                  <avue-input v-model="activeObj.name"></avue-input>
                </el-form-item>
                <el-form-item label="隐藏">
                  <avue-switch v-model="activeObj.display"></avue-switch>
                </el-form-item>
                <template v-if="vaildProp('colorList')">
                  <el-form-item label="系统配色">
                    <avue-switch v-model="activeOption.switchTheme"></avue-switch>
                  </el-form-item>
                  <el-form-item label="配色选择" v-if="activeOption.switchTheme">
                    <avue-select v-model="activeOption.theme" :dic="dicOption.themeList"> </avue-select>
                  </el-form-item>
                </template>
                <component :is="activeComponent.prop + 'Option'"></component>
                <main-option></main-option>
              </template>
              <!-- 多选配置选项 v-else-if="isSelectActive"-->
              <template v-else-if="isSelectActive">
                <el-form-item label="水平方式">
                  <el-tooltip content="左对齐" placement="top">
                    <i class="el-icon-s-fold icon" @click="$refs.container.handlePostionSelect('left')"></i>
                  </el-tooltip>
                  <el-tooltip content="居中对齐" placement="top">
                    <i class="el-icon-s-operation icon" @click="$refs.container.handlePostionSelect('center')"></i>
                  </el-tooltip>
                  <el-tooltip content="右对齐" placement="top">
                    <i class="el-icon-s-unfold icon" @click="$refs.container.handlePostionSelect('right')"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="垂直方式">
                  <el-tooltip content="顶对齐" placement="top">
                    <i class="el-icon-s-fold icon" @click="$refs.container.handlePostionSelect('top')"></i>
                  </el-tooltip>
                  <el-tooltip content="中部对齐" placement="top">
                    <i class="el-icon-s-operation icon" @click="$refs.container.handlePostionSelect('middle')"></i>
                  </el-tooltip>
                  <el-tooltip content="底对齐" placement="top">
                    <i class="el-icon-s-unfold icon" @click="$refs.container.handlePostionSelect('bottom')"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-button type="primary" size="mini" class="block" @click="handleFloder">成组</el-button>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-button type="danger" size="mini" class="block" @click="handleDeleteSelect">删除</el-button>
                </el-form-item>
              </template>
              <!-- 主屏的配置项  v-else-->
              <template v-else>
                <el-form-item label="大屏名称">
                  <avue-input v-model="config.name"></avue-input>
                </el-form-item>
                <el-form-item label="大屏宽度">
                  <avue-input-number v-model="config.width"></avue-input-number>
                </el-form-item>
                <el-form-item label="大屏高度">
                  <avue-input-number v-model="config.height"></avue-input-number>
                </el-form-item>
                <el-form-item label="大屏简介">
                  <avue-input v-model="config.info" type="textarea" :min-rows="5"></avue-input>
                </el-form-item>
                <el-form-item label="背景颜色">
                  <avue-input-color v-model="config.backgroundColor"></avue-input-color>
                </el-form-item>
                <el-form-item label="背景图片">
                  <img :src="config.backgroundImage" @click="handleOpenImg('config.backgroundImage', 'background')" alt="" width="100%" />
                </el-form-item>
                <el-form-item label="公共地址">
                  <avue-input type="textarea" :min-rows="3" v-model="config.url"></avue-input>
                </el-form-item>
                <el-form-item label="公共请求参数">
                  <el-button size="mini" type="primary" @click="openCode('query')">编辑函数</el-button>
                </el-form-item>
                <el-form-item label="公共请求头">
                  <el-button size="mini" type="primary" @click="openCode('header')">编辑函数</el-button>
                </el-form-item>
                <el-form-item label="水印(预览有效)">
                  <avue-switch v-model="config.mark.show"></avue-switch>
                </el-form-item>
                <template v-if="config.mark.show">
                  <el-form-item label="内容">
                    <avue-input v-model="config.mark.text"></avue-input>
                  </el-form-item>
                  <el-form-item label="大小">
                    <avue-input-number v-model="config.mark.fontSize"></avue-input-number>
                  </el-form-item>
                  <el-form-item label="颜色">
                    <avue-input-color v-model="config.mark.color"></avue-input-color>
                  </el-form-item>
                  <el-form-item label="角度">
                    <avue-input-number v-model="config.mark.degree"></avue-input-number>
                  </el-form-item>
                </template>
              </template>
            </el-form>
          </el-tab-pane>
          <!-- 数据配置 -->
          <el-tab-pane name="1" v-if="vaildProp('dataList')">
            <el-tooltip slot="label" effect="dark" content="数据" placement="top">
              <div><i class="el-icon-document-copy"></i></div>
            </el-tooltip>
            <el-form label-width="120px" label-position="left" size="mini">
              <el-form-item label="数据类型">
                <avue-radio v-model="activeObj.dataType" :dic="dicOption.dataType"></avue-radio>
              </el-form-item>
              <el-form-item label="刷新时间">
                <avue-input-number v-model="activeObj.time"></avue-input-number>
              </el-form-item>
              <template v-if="isApi || isSql">
                <el-form-item label-width="0">
                  <el-button size="mini" type="primary" class="block" @click="handleSql">
                    <span v-if="isSql">编辑SQL语句</span>
                    <span v-else-if="isApi">编辑Api接口</span>
                  </el-button>
                </el-form-item>
              </template>
              <el-form-item label-width="0" v-if="isStatic">
                <el-button size="mini" type="primary" class="block" @click="handleSql">
                  <span>编辑静态数据</span>
                </el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <el-button size="mini" type="primary" class="block" @click="openCode('stylesFormatter')">编辑样式</el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <el-button size="mini" type="primary" class="block" @click="handleRes">刷新数据</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 交互事件配置 -->
          <el-tab-pane name="2" v-if="vaildProp('eventList')">
            <el-tooltip slot="label" effect="dark" content="交互" placement="top">
              <div><i class="el-icon-thumb"></i></div>
            </el-tooltip>
            <el-form label-width="120px" label-position="left" size="mini">
              <el-form-item label="子类">
                <avue-select multiple v-model="activeObj.child.index" :dic="childList" :props="childProps"> </avue-select>
              </el-form-item>
              <el-form-item label="参数名称">
                <avue-input v-model="activeObj.child.paramName"></avue-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 其他事件配置 -->
          <el-tab-pane name="3" v-if="!vaildProp('', [undefined])">
            <el-tooltip slot="label" effect="dark" content="事件" placement="top">
              <div><i class="iconfont icon-peizhi"></i></div>
            </el-tooltip>
            <el-form label-width="120px" label-position="left" size="mini">
              <el-form-item label="点击事件">
                <el-button size="mini" type="primary" @click="openCode('clickFormatter')">编辑</el-button>
              </el-form-item>
              <el-form-item label="提示事件" v-if="vaildProp('labelFormatterList')">
                <el-button size="mini" type="primary" @click="openCode('formatter')">编辑</el-button>
              </el-form-item>
              <el-form-item label="标题事件" v-if="vaildProp('labelFormatterList')">
                <el-button size="mini" type="primary" @click="openCode('labelFormatter')">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 基本参数配置 -->
          <el-tab-pane name="4" v-if="isActive">
            <el-tooltip slot="label" effect="dark" content="参数" placement="top">
              <div><i class="el-icon-folder"></i></div>
            </el-tooltip>
            <el-form label-width="120px" label-position="left" size="mini">
              <el-form-item label="序号">
                <avue-input v-model="activeObj.index" disabled></avue-input>
              </el-form-item>
              <el-form-item label="X位置">
                <avue-input-number v-model="activeObj.left"></avue-input-number>
              </el-form-item>
              <el-form-item label="Y位置">
                <avue-input-number v-model="activeObj.top"></avue-input-number>
              </el-form-item>
              <el-form-item label="宽度">
                <avue-input-number v-model="activeComponent.width"></avue-input-number>
              </el-form-item>
              <el-form-item label="高度">
                <avue-input-number v-model="activeComponent.height"></avue-input-number>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <codeedit @submit="codeClose" v-if="code.box" :type="code.type" v-model="code.obj" :visible.sync="code.box"></codeedit>
    <contentmenu ref="contentmenu"></contentmenu>
    <el-dialog append-to-body :close-on-click-modal="false" :visible.sync="show" width="60%">
      <el-form size="small" v-if="show" label-width="130px">
        <template v-if="isStatic">
          <el-form-item label="数据值" label-position="top">
            <el-button size="mini" type="primary" @click="openCode('data')">编辑JSON</el-button>
          </el-form-item>
          <el-form-item label="数据值" label-position="top">
            <el-upload :show-file-list="false" :auto-upload="false" accept=".xls,.xlsx" :on-change="handleImport">
              <el-button size="mini" type="success">导入数据(Excel)</el-button>
            </el-upload>
          </el-form-item>
        </template>
        <template v-else-if="isSql">
          <el-form-item label="数据源选择">
            <avue-select :dic="DIC.sql" v-model="db"></avue-select>
          </el-form-item>
          <el-form-item label="SQL语句" label-position="top">
            <code-editor v-model="sql" language="sql" height="100"></code-editor>
          </el-form-item>
        </template>
        <template v-else-if="isApi">
          <el-form-item label="接口地址">
            <avue-input v-model="activeObj.url"></avue-input>
          </el-form-item>
          <el-form-item label="请求方式">
            <avue-select v-model="activeObj.dataMethod" :dic="dicOption.dataMethod"></avue-select>
          </el-form-item>
          <el-form-item label="请求头">
            <el-button size="small" type="primary" @click="openCode('dataHeader')">编辑函数</el-button>
          </el-form-item>
          <el-form-item label="请求参数">
            <el-button size="small" type="primary" @click="openCode('dataQuery')">编辑函数</el-button>
          </el-form-item>
        </template>
        <el-form-item label="响应数据">
          <code-editor v-model="dataRes" disabled height="300"></code-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="danger" @click="openCode('dataFormatter')">数据处理</el-button>
        <el-button size="small" type="primary" @click="handleRes">刷新数据</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
//import MonacoEditor from './components/editor'
import layer from './group/layer'
import top from './group/top'
import headers from './group/header'
import imglist from './group/imglist'
import contentmenu from './group/contentmenu'
import codeedit from './group/code'
import container from './group/container'
import { dicOption } from './option/config'
import init from '@/mixins/'
import { createFile } from './utils/utils'
import components from './option/components'
import SketchRule from 'vue-sketch-ruler'
import crypto from './utils/crypto'
import { getAllDsList } from '@/api/hbi/dynamicdatasource.js'

export default {
  mixins: [init, components],
  data() {
    return {
      menuShow: true,
      paramsShow: true,
      show: false,
      keys: {
        ctrl: false
      },
      dataRes: '',
      db: '',
      sql: '',
      nav: [],
      DIC: {
        sql: []
      },
      loading: '',
      childProps: {
        label: 'name',
        value: 'index'
      },
      key: '',
      menuFlag: true,
      code: {
        box: false,
        type: '',
        obj: ''
      },
      form: {},
      dicOption: dicOption,
      tabsActive: 0,
      // 标尺
      scale: 0.9, //初始化标尺的缩放
      startX: 0, //x轴标尺开始的坐标数值
      startY: 0,
      lines: {
        //初始化水平标尺上的参考线
        h: [],
        v: []
      },
      rendIndex: 0, //重新渲染组件
      shadow: { x: 0, y: 0 }, // 阴影大小
      thick: 20, //标尺的厚度
      width: 0, // 标尺宽,后面会初始化
      height: 0, // 标尺高,后面会初始化
      isShowReferLine: true, // 显示参考线
      isImgOpen: true, //眼镜打开
      imgOpenData:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAbCAYAAAAOEM1uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAQNSURBVHja7JdvSON1HMdfP126/shSmaZ1ntuZbTLihOnSdJlPhIquB0VR1DZM9En0wCB3qCXKVOh86mmakdGDOqyHityBidYN1HPYZqbhOZprMGTOUk/9/XryWyxvek5NIu4Lg/H+fPj8Xt/P98/n8xUkSeK/PIT7gP8GoCAI8cTQAoWAHkgFRCAA3AKmgeBRA8VkOSZgMvAy8DZQCqQf4OcFRoDPgYmzAnwdaAAuxpFlCbgGfAR4ThUwYhcE4QngExnw71FWVuax2WwBk8mkSE9PV+7t7Ymrq6vbw8PD0uDgYO7CwsK5KPc14ENJkj497FtxAwqCYAK+kvcbANXV1U6Hw6HIyMh4GlAckJHwzMzMrM1my3a5XNoo01XgPUmSdk8MCLwEfAmoAPLz872jo6OrOTk5xVGBQ0tLS575+fnt7OzsRIPBcD4pKelctL2/v3+mtrbWLIpigixfA94BNk8C+JoMlyRn7WZvb68mISEhI+IQCASmKyoq0jweT25EU6lU4aGhoZnKykpzdNzl5eWbRqPxyWAwmCZL3wJvAHeOA/iCPMsHARwOx7jdbi+JXs7t7e3lrKys1LW1NVWsJXa73ZN6vb40WltfX3cbDIZ0r9ebKUv9wLvxAhYC1+V7je7u7rG6urrn9vu1tbWNNzU1lR90KgsLC5emp6cfB5TRejgc9mg0msyoTNYDXfEAjgFmQGxpaZlobm6OBbFrMpl+dTqd+YdcLztbW1ve5ORk7X6D3++f0ul0+aFQKAXYAF6RJOn6UQGDQBrgE0VRJQjCw7EAjUbj8tTUVN4hgLubm5u3lUrlhVjG+vr6ya6ursgWaJck6fJRAW8AzwNia2vrRGNjY8xltNvtEx0dHc8eRKfX62+73W418NB+m8/nm9LpdE+Fw+FHgD+AS/Fk8CJwI7IHe3t7x2pqau7agxsbG/NqtTp3a2tLGQtwcnJyvKSk5K7JhcPhnzQaTXYwGEyVpQ+AK8c5xd9EZt/e3v59Q0ND6f5LeXFx8cfy8vILfr9fHdEUCsVOX1/fhMViqdgfOxQKzRUUFKh9Pt+JTnHk76vyPZgcqR49PT3nExMTM/+x0XZ3fePj47/Mzc2RlZVFVVVVRkpKin5/3JWVFWdRUVFeIBCInN7v5NJ55ySV5EUZ8lEArVb728jIiDcvL++ZONqo0MDAwK2ampoyURQTZXlI7ob+PI1aXAR8AegiutVqdXZ2dt6zFs/OzrosFstjLpcr+iR3A+9LkrRzKs2CrGUCV4C3on3NZrPHarX+Xlxc/MARu5nLkiRdPbVuJsa4BDQCxjj6QRH4GvgY+PksOmqF3FG/KVcc9T066s+AH86y5Y8eOXI282XQyJtkRv6d/pvk/rPz/wT41wBibRrpeMs+PAAAAABJRU5ErkJggg==', // 左上角图片
      imgClose:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAPYSURBVHja7JVPTFRXFIe/+96gGRHEhQzBFMJKSKSkLTgtGxemSXcktohW2RBmTAjUClIYZ6Cxi+fQDgOCLJgJhqTSBAtLdjaxaSqQWnDSNp2U0I6FFh0SDdV0oMx7pwuZCUXjnyZNuvBsbnLvufe7555zfleJCP+1qReQfwVRSqWmqoFDIE3A+iZXQDbGlMmmNTatP5xPn/0ohOOgLgNtIB8DOlAKvAzsBTKBP4FF4Dvge1DrzwsBaAAuAJ8CxbpuezU/P397QcFLZGVlcf/+fRYXF1lc/G3VNJM/AJ8Dw8CdZ4QoQI4AIWBXaWkpQ0ND5v79+zW73Z5+n9XVVWZnb8rExIQ2MnKZWCz2M/Dhw1d4eiTngXafz2dmZ2ebPp9P6+vrl5qaI2p8fFyi0aheUlJiHj78tpaTs0sHJB6PW4HAJ3og0I2I+AHPkyAeu91uDA4OmrW1tRpAT09vsr29XXM4ciWZTJKXl2ctLCyoHTsy1ZUro+J0OjNSWR8ZGbFcLpeeSCTOAucfBykDpvr7+7c3NjamS+bevXvJ4uIS4vE7tra2tjW/32+7ffu21Ne7rJmZb7VIJKL27Nmjp0ADAwM0NjauAW+IyM2tkAGn8/WG69e/NjVNS20iHA6vd3Z2qrq6OtMwDFswGLROnz6dcffu3WRRUZEVCARwuVzbUv6WZVmVlZXa9PT0RRFp2gq56na7Dw0ODv6jGc6cOWNdu/Zl8saNb/RgMGi1tLSo7u5uaW5u1srLK8yDBw/aursD2ubmcbtPqnA4dFVE3twKuVBeXv7e5OSkabPZ0pGEQuG/WltbicV+0Xbv3m0LBnvWW1qatbNnvclLl4b0c+c+ErfblcoLa2trptPp1CORSEBEWrdCSoBpwzB2ejyedE6Wl5fNsrIyqaiokFAopBwOh/J4PKbf79/mcDiS0WiUnJwcWyoKr9erDMNYBg6ISOxx1dWg6/pAb2+vtZF8DWBqanr96NEalUgkpKCgQJaWljRN04jH43R1dcmpU6dsgBiGobxer3qoGnz2pD5pBfx1dXWaz+czi4qKNECtrKyYY2NjVjQa1fftK7aqq99hdHRUmpqaVEdHhzU/P58xPDycAN4Hws8iK28B/tzc3LJjx96lqqrKOnCggszMzLRmPHjwQM3MzFj19fX63NwcwDJQBUymK+ApEEDtBDkOnFBKvZafv9deWFhIdnYWKysr3Lr1K0tLvydE5CvgJ1AnQZqBi88DSV1aA0qAV4CCDRX+A1gAZoEfN/w/ALqAEyAjzwvZ8mc8KukblgGqD/gCZOyxkBd//P8G8vcAMK383pmr7aEAAAAASUVORK5CYII=',
      dragSlide: false, // 拖动滚动条标记
      id: ''
    }
  },
  props: {
    params: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  components: {
    //MonacoEditor,
    imglist,
    layer,
    codeedit,
    top,
    headers,
    contentmenu,
    SketchRule,
    container
  },
  computed: {
    isKeysCtrl() {
      return this.keys.ctrl == true
    },
    isStatic() {
      return this.activeObj.dataType == 0
    },
    isApi() {
      return this.activeObj.dataType == 1
    },
    isSql() {
      return this.activeObj.dataType == 2
    },
    isFolder() {
      return this.activeObj.children
    },
    isActive() {
      return true
      //return this.active.length !== 0
    },
    isSelectActive() {
      return false
      //return this.active.length > 1;
    },
    childList() {
      return this.list.filter(ele => {
        if (['tabs'].includes(ele.component.prop)) {
          return false
        }
        return true
      })
    },
    activeComponent() {
      return this.activeObj.component || {}
    },
    activeOption() {
      return this.activeObj.option || {}
    },
    activeObj() {
      if (this.validatenull(this.activeIndex)) return {}
      let item = this.findlist(this.activeIndex)
      if (!item.child) item.child = {}
      return item
    },
    activeList() {
      let result = []
      this.active.forEach(ele => {
        const item = this.findnav(ele, true)
        result.push(item.obj)
      })
      return result
    },
    /* 标尺用的 */
    palette() {
      return {
        bgColor: '#181b24', // ruler bg color
        longfgColor: '#BABBBC', // ruler longer mark color
        shortfgColor: '#9C9C9C', // ruler shorter mark color
        fontColor: '#fff', // ruler font color
        shadowColor: '#181b24', // ruler shadow color
        lineColor: '#EB5648',
        borderColor: '#B5B5B5',
        cornerActiveColor: '#fff'
      }
    },
    // 画布大小,一定要是computer里面,否则缩放页面会失效
    canvasStyle() {
      return {
        width: window.innerWidth - 530 + 'px',
        transform: `scale(${this.scale})`
      }
    }
  },
  watch: {
    activeObj: {
      handler(val) {
        if (this.activeObj.sql && this.isSql) {
          let mode = JSON.parse(crypto.decrypt(this.activeObj.sql))
          this.db = mode.id
          this.sql = mode.sql
        } else {
          this.db = ''
          this.sql = ''
        }
      },
      deep: true
    },
    menuFlag() {
      this.setResize()
    },
    overactive(n, o) {
      ;[o, n].forEach((ele, index) => {
        if (!ele) return
        this.setActive(ele, index === 1, 'setOverActive')
      })
    },
    active(n, o) {
      ;[o, n].forEach((ele, index) => {
        ele.forEach(item => {
          this.setActive(item, index === 1, 'setActive')
        })
      })
      //隐藏右键菜单
      this.$refs.contentmenu.hide()
      // 初始化选项卡
      this.tabsActive = '0'
    },
    params: {
      handler(newVal, oldVal) {
        //console.log('oldVal',oldVal);
        if (Object.prototype.hasOwnProperty.call(newVal, 'id')) {
          this.id = newVal.id
        } else {
          this.id = this.$route ? this.$route.params.id : this.props.id
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.listen()
    this.iniresize()
    this.initSqlList()
  },
  mounted() {
    this.initFun()
    this.$nextTick(() => {
      this.initSize()
    })
  },
  methods: {
    toDo(param) {
      if (param) {
        this.$emit('getMsg', 'create')
      } else {
        window.close()
      }
    },
    handleImport(file, fileLis) {
      this.$Export.xlsx(file.raw).then(data => {
        this.activeObj.data = data.results
        this.$message.success('导入成功')
        this.handleRes()
      })
    },
    handleRefresh() {
      return this.$refs.container.handleRefresh()
    },
    handleRes() {
      if (this.isSql) {
        this.$set(
          this.activeObj,
          'sql',
          crypto.encrypt(
            JSON.stringify({
              id: this.db,
              sql: this.sql
            })
          )
        )
      }
      this.handleRefresh().then(res => {
        if (!this.validatenull(res)) {
          this.dataRes = JSON.stringify(res || {}, null, 4)
        } else {
          this.dataRes = ''
        }
        this.$message.success('数据刷新成功')
      })
    },
    handleSql() {
      this.show = true
      this.dataRes = ''
      this.$nextTick(() => {
        let result = false
        if (this.isSql && !this.validatenull(this.sql)) {
          result = true
        } else if (this.isApi && !this.validatenull(this.activeObj.url)) {
          result = true
        } else {
          result = true
        }
        if (result) this.handleRes()
      })
    },
    initSqlList() {
      getAllDsList(1, 100).then(res => {
        const data = res.data.data
        this.DIC.sql = data.map(ele => {
          return {
            label: ele.name,
            value: ele.id
          }
        })
      })
    },
    codeClose(value) {
      if (this.configData.includes(this.code.type)) {
        this.config[this.code.type] = value
      } else {
        this.activeObj[this.code.type] = value
      }
    },
    openCode(type) {
      this.code.type = type
      if (this.configData.includes(type)) {
        this.code.obj = this.config[type]
      } else {
        this.code.obj = this.activeObj[type]
      }
      this.code.box = true
    },
    initFun() {
      ;['setScale', 'setResize'].forEach(ele => {
        this[ele] = this.$refs.container[ele]
      })
      ;['handleAdd'].forEach(ele => {
        this[ele] = this.$refs.top[ele]
      })
    },
    // 右键菜单
    handleContextMenu(e, item = {}) {
      if (!item.index || this.isKeysCtrl) return
      else if (!this.isSelectActive) {
        this.active = [item.index]
        this.activeIndex = item.index
      }
      this.$nextTick(() => this.$refs.contentmenu.show(e.clientX, e.clientY))
    },
    //监听键盘的按键
    listen() {
      document.onkeydown = e => {
        this.keys.ctrl = e.keyCode === 17
        if (e.target.nodeName == 'TEXTAREA' || e.target.nodeName == 'INPUT') return
        // 按下空格键
        if (e.keyCode == 32) {
          e.preventDefault()
          this.keys.space = true
        }
      }
      document.onkeyup = () => {
        this.keys.ctrl = false
      }
    },
    setActive(val, result, fun) {
      const obj = this.$refs.container.getDragObj(val)
      if (!this.validatenull(obj)) obj[0][fun](result)
    },
    //批量成组
    handleFloder() {
      let floder = createFile()
      this.active.forEach(index => {
        const params = this.findnav(index)
        floder.children.push(params.obj)
        params.parent.splice(params.count, 1)
      })
      this.nav.push(floder)
      this.handleInitActive()
    },
    //批量删除
    handleDeleteSelect() {
      this.$confirm(`是否批量删除所选图层?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.active.forEach(index => {
          const params = this.findnav(index)
          params.parent.splice(params.count, 1)
        })
        this.handleInitActive()
      })
    },
    vaildProp(name, list) {
      if (list) return list.includes(this.activeComponent.prop) || this.active.length > 1
      return this.dicOption[name].includes(this.activeComponent.prop)
    },
    formatTooltip(val) {
      return parseInt(val)
    },
    //打开图库
    handleOpenImg(item, type) {
      this.$refs.imglist.openImg(item, type)
    },
    //图库框回调赋值
    handleSetimg(val, type) {
      let params = type.split('.')[1]
      if (type.includes('config')) {
        this.config[params] = val
      } else if (type.includes('activeObj.data.value')) {
        this.activeObj.data.value = val
      } else if (type.includes('activeObj.data')) {
        this.activeObj.data = val
      } else if (type.includes('activeObj')) {
        this.activeObj[params] = val
      } else if (type.includes('activeOption')) {
        this.activeOption[params] = val
      }
    },
    /* **************************标尺方法开始******************************* */
    // 滚轮触发
    handleScroll() {
      const screensRect = this.$refs.screensRef.getBoundingClientRect()
      const canvasRect = this.$refs.canvas.getBoundingClientRect()
      // 标尺开始的刻度
      const startX = (screensRect.left + this.thick - canvasRect.left) / this.scale
      const startY = (screensRect.top + this.thick - canvasRect.top) / this.scale
      this.startX = startX >> 0
      this.startY = startY >> 0
    },
    // 控制缩放值
    handleWheel(e) {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
        const nextScale = parseFloat(Math.max(0.2, this.scale - e.deltaY / 500).toFixed(2))
        this.scale = nextScale
      }
      this.$nextTick(() => {
        this.handleScroll()
      })
    },
    // 初始化标尺数值
    initSize() {
      const domW = this.$refs.section
      let width = window.innerWidth - 530
      let height = window.innerHeight - 45
      domW.style.width = width + 'px'
      domW.style.height = height + 'px'
      this.width = width - this.thick
      this.height = height - this.thick
      // 画布阴影部分
      this.shadow = { x: 0, y: 0, width, height }
      // 滚动居中
      let dom = this.$refs.containerRef.getBoundingClientRect()
      this.$refs.screensRef.scrollLeft = dom.width / 2 - this.thick
      this.$refs.screensRef.scrollTop = dom.height / 2 - this.thick
    },
    // resize
    iniresize() {
      window.addEventListener('resize', () => {
        let width = this.width
        let height = this.height
        this.initSize()
        let rate = Math.min(this.width / width, this.height / height)
        this.scale = rate > 1 ? rate * 0.5 : rate
        this.rendIndex++
      })
    },
    // 图片点击事件
    imgClick() {
      this.isShowReferLine = !this.isShowReferLine
    },
    // 鼠标按下事件
    dragMousedown(e) {
      // 如果按下了空格键,且按下鼠标左键,那么鼠标变抓手,开启滚动条随鼠标拖动的操作
      this.handleInitActive()
      if (this.keys.space) {
        this.dragSlide = true
        window.stardragEvent = e
        window.startSlideX = this.$refs.screensRef.scrollLeft
        window.startSlideY = this.$refs.screensRef.scrollTop
      }
    },
    //鼠标抬起操作
    dragMouseup() {
      this.dragSlide = false
    },
    // 鼠标移动骚操作
    dragMousemove(e) {
      if (this.dragSlide) {
        let x = e.clientX - window.stardragEvent.clientX
        let y = e.clientY - window.stardragEvent.clientY
        this.$refs.screensRef.scrollLeft = window.startSlideX - x
        this.$refs.screensRef.scrollTop = window.startSlideY - y
      }
    },
    selectNav(item) {
      if (Array.isArray(item)) {
        this.active = this.active.concat(item)
      } else if (this.isKeysCtrl) {
        this.active.push(item)
      } else if (!this.active.includes(item)) {
        this.active = [item]
        this.activeIndex = item
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/build.scss';
.refer-line-img {
  position: absolute;
  left: 0;
  z-index: 5;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
#screens {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.screen-container {
  position: relative;
  width: 5000px;
  height: 3000px;
  background: url(https://img.alicdn.com/tfs/TB184VLcPfguuRjSspkXXXchpXa-14-14.png) repeat;
}

.dragghanle {
  cursor: pointer;
}
.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
}
.section {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.build {
  .el-collapse {
    border-top: 1px solid #282e3a !important;
  }

  .el-collapse-item__wrap {
    background-color: #000;
    border-bottom: none;
  }

  .el-collapse-item__content {
    padding: 0;
  }

  .el-form-item__label {
    padding-left: 20px;
  }

  .el-form-item {
    margin-top: 10px;
  }

  .el-form-item--mini .el-form-item {
    margin-bottom: 18px;
  }

  .el-form-item__content {
    padding-right: 20px;
  }
}

body {
  overflow: hidden;
}

.visualization .el-tabs__content {
  overflow-y: scroll;
  min-height: 300px;
  height: calc(100vh * 0.6);
}
</style>
