<!-- 进度条配置 -->
<template>
  <div>
    <el-form-item label="使用多行">
      <avue-radio v-model="main.activeOption.multiple" :dic="multiple">
      </avue-radio>
    </el-form-item>

    <template v-if="main.activeOption.multiple">
      <el-form-item label="列表的高度">
        <avue-input v-model="main.activeOption.multipleListHeight">
        </avue-input>
      </el-form-item>
    </template>

    <el-form-item label="类型">
      <avue-radio v-model="main.activeOption.type" :dic="dicOption.line">
      </avue-radio>
    </el-form-item>
    <el-form-item label="间距">
      <avue-input-number v-model="main.activeOption.split" :max="200"></avue-input-number>
    </el-form-item>
    <el-form-item label="边框颜色">
      <avue-input-color v-model="main.activeOption.borderColor"></avue-input-color>
    </el-form-item>
    <el-form-item label="边框大小">
      <avue-input-number v-model="main.activeOption.strokeWidth" :max="50"></avue-input-number>
    </el-form-item>
    <el-form-item label="字体大小">
      <avue-input-number v-model="main.activeOption.fontSize" :max="200"></avue-input-number>
    </el-form-item>
    <el-form-item label="字体颜色">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
    <el-form-item label="文字粗细">
      <avue-select v-model="main.activeOption.FontWeight" :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
    <el-form-item label="前缀字体大小">
      <avue-input-number v-model="main.activeOption.suffixFontSize" :max="200"></avue-input-number>
    </el-form-item>
    <el-form-item label="前缀字体颜色">
      <avue-input-color v-model="main.activeOption.suffixColor"></avue-input-color>
    </el-form-item>
    <el-form-item label="前缀文字粗细">
      <avue-select v-model="main.activeOption.suffixFontWeight" :dic="dicOption.fontWeight">
      </avue-select>
    </el-form-item>
    <el-form-item label="前缀文本边距">
      <avue-input v-model="main.activeOption.suffixFontMargin">
      </avue-input>
    </el-form-item>
    <el-form-item label="元素点击事件">
      <avue-input v-model="main.activeOption.itemClikFuncBody" placeholder="参数为item">
      </avue-input>
    </el-form-item>

  </div>
</template>

<script>
import { dicOption } from '../config'
export default {
  data() {
    return {
      dicOption: dicOption,
      // 多行
      multiple: [
        { label: '不使用', value: undefined },
        { label: '使用', value: 'multiple' }
      ]
    }
  },
  inject: ["main"]
}
</script>

<style>
</style>
