<!-- 自定义配置 -->
<template>
  <div>
    <el-form-item label="使用选择器">
      <avue-switch type="textarea" v-model="main.activeOption.switchSelected"></avue-switch>
    </el-form-item>

    <el-form-item label="使用全屏按钮">
      <avue-switch type="textarea" v-model="main.activeOption.switchFullScreenBtn"></avue-switch>
    </el-form-item>

    <!-- 
      ### 注意: 使用默认值,将此组件置于顶层,被首先渲染的时候将设置区划的默认值 ###
      默认使用的区划编码,在省级的时候url没有带areaCode,造成多个省级地图查询参数areaCode是一样,造成查询错误,由此增加默认值
     -->
    <el-form-item label="默认区划代码">
      <avue-input v-model="main.activeOption.defaultAreaCode"></avue-input>
    </el-form-item>

    <!-- 下拉菜单 -->
    <template v-if="!main.activeOption.switchSelected && !main.activeOption.switchFullScreenBtn">
      <el-form-item label="提示文字">
        <avue-input v-model="main.activeOption.linkText"></avue-input>
      </el-form-item>
      <el-form-item label="显示字体大小">
        <avue-input-number v-model="main.activeOption.linkFontSize"></avue-input-number>
      </el-form-item>
      <el-form-item label="显示字体颜色">
        <avue-input-color v-model="main.activeOption.linkFontColor"></avue-input-color>
      </el-form-item>
      <el-form-item label="点击函数">
        <avue-input v-model="main.activeOption.evalFunction"></avue-input>
      </el-form-item>
    </template>

    <!-- 选择器 -->
    <template v-if="main.activeOption.switchSelected">
      <el-form-item label="命名空间(NS)">
        <avue-input v-model="main.activeOption.nameSpace" placeholder="柱形图,折线图,饼形图改变数据"></avue-input>
      </el-form-item>
      <el-form-item v-if="main.activeOption.nameSpace" label="NS-请求API">
        <avue-input v-model="main.activeOption.nsapi"></avue-input>
      </el-form-item>
      <el-form-item label="输入框样式">
        <avue-input v-model="main.activeOption.selectInputStyle"></avue-input>
      </el-form-item>

      <!--  这里的样式可以输入多组,将来会拼接父选择器 -->
      <el-form-item label="下拉框样式">
        <avue-input v-model="main.activeOption.popperInputStyle" placeholder="将来会拼接父选择器"></avue-input>
      </el-form-item>
    </template>

    <!-- 切换到全屏按钮 -->
    <template v-if="main.activeOption.switchFullScreenBtn">
      <el-form-item label="按钮的样式">
        <avue-input v-model="main.activeOption.fullScreenBtnStyle" placeholder="将来会拼接父选择器"></avue-input>
      </el-form-item>
    </template>
  </div>
</template>

<script>
export default {
  inject: ['main'],
  data() {
    return {
      linkText: '下拉菜单'
    }
  },
  methods: {
    toggleSwitch(name, value) {
      const names = ['switchSelected', 'switchFullScreenBtn']
      // 取反
      names.forEach(val => {
        if (value && val != name) {
          this.main.activeOption[val] = false
        }
      })

    }
  },

  watch: {
    'main.activeOption.switchSelected'(value) {
      this.toggleSwitch('switchSelected', value)
    },
    'main.activeOption.switchFullScreenBtn'(value) {
      console.log('值改变了', value)
      this.toggleSwitch('switchFullScreenBtn', value)
    },
  },
}
</script>

<style></style>
